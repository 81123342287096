import Button from "./button";
import Box from "./box";
import Input from "./input";
import Textarea from "./textarea";
import Popup from "./popup";
import { useEffect, useState } from "react";
import { Api } from "../services/Api";
import { platformEvents } from "../services/eventbus/platformEvents";
import { useSanctum } from "react-sanctum";
import { Content } from "../types/Content";
import Select from "./select";
import { forEach } from "lodash";
import { HotspotIcon } from "../types/SystemHotspot";

interface Props {
  type?: "Link" | "Text";
  title?: string;
  text?: string;
  link?: number;
  linkableContent: Content[];
  icon?: HotspotIcon;
  file_path?: any;
  onCancel: () => void;
  onClose: (
    type: "Link" | "Text" | "External Link" | "Video" | "Audio" | "Image",
    title?: string,
    text?: string,
    link?: number,
    icon?: HotspotIcon,
    file?: any,
  ) => void;
}

export default function EditHotspot({
  type,
  title,
  text,
  link,
  linkableContent,
  icon,
  file_path,
  onCancel,
  onClose,
}: Props) {
  const [hsTitle, setHsTitle] = useState<string>(title ?? "");
  const [hsText, setHsText] = useState<string>(text ?? "");
  const [hsLink, setHsLink] = useState<number | undefined>(link ?? undefined);
  const [hsLinkText, setHsLinkText] = useState<string>("");
  const [hsIcon, setHsIcon] = useState<HotspotIcon>(icon ?? "Standard Hotspot");
  const [hsType, setHsType] = useState<
    "Link" | "Text" | "External Link" | "Video" | "Audio" | "Image"
  >(type ?? "Text");
  const [content, setContent] = useState<string[]>([]);
  const [hsFile, setHsFile] = useState<any>(null);

  useEffect(() => {
    if (hsLink && content.length) {
      const cnt = content.find((content) => content.includes(hsLink + ":"));
      if (cnt) setHsLinkText(cnt);
    }
  }, [hsLink, content]);
  useEffect(() => {
    if (content.length === 0) {
      const newContent: string[] = [];
      linkableContent.forEach(function (content) {
        newContent.push(`${content.id}: ${content.description.title}`);
      });
      setContent(newContent);
    }
  }, [linkableContent]);

  const handleSubmit = () => {
    onClose(hsType, hsTitle, hsText, hsLink, hsIcon, hsFile);
  };

  return (
    <Popup>
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Edit Hotspot</h2>

          <div className="space-y-sm">
            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <label htmlFor="type">Type</label>
              </div>
            </div>
            <Select
              options={[
                "Link",
                "Text",
                "External Link",
                "Video",
                "Audio",
                "Image",
              ]}
              value={hsType}
              valueChanged={(e) =>
                setHsType(
                  e as
                    | "Link"
                    | "Text"
                    | "External Link"
                    | "Video"
                    | "Audio"
                    | "Image",
                )
              }
            />
          </div>

          <div className="space-y-sm">
            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <label htmlFor="icon">Icon</label>
              </div>
            </div>
            <Select
              options={[
                "Standard Hotspot",
                "Image",
                "Video",
                "Audio",
                "Question",
              ]}
              value={hsIcon}
              valueChanged={(e) => setHsIcon(e as HotspotIcon)}
            />
          </div>

          {hsType === "Link" && (
            <div className="space-y-sm">
              <div className="flex justify-between">
                <div className="flex items-center gap-sm">
                  <label htmlFor="link">Content</label>
                </div>
              </div>
              {content && content.length ? (
                <Select
                  options={content}
                  value={hsLinkText}
                  valueChanged={(e) => {
                    const id = e.split(": ")[0] ?? null;
                    if (id) {
                      setHsLink(parseInt(id));
                      setHsLinkText(e);
                    }
                  }}
                />
              ) : (
                <p className="pt-sm text-stone-500">
                  No content available to link up.
                </p>
              )}
            </div>
          )}

          {(hsType === "Text" || hsType === "External Link") && (
            <>
              <div className="space-y-sm">
                <div className="flex justify-between">
                  <div className="flex items-center gap-sm">
                    <label htmlFor="title">Title</label>
                  </div>
                </div>
                <Input
                  type="text"
                  id="title"
                  placeholder="Title"
                  value={hsTitle}
                  onChange={(e) => setHsTitle(e.target.value)}
                  maxLength={200}
                />
              </div>

              <div className="space-y-sm">
                <div className="flex justify-between">
                  <div className="flex items-center gap-sm">
                    <label htmlFor="description">
                      {hsType === "Text" ? "Text" : "URL"}
                    </label>
                  </div>
                </div>
                <Textarea
                  id="text"
                  placeholder={hsType === "Text" ? "Text" : "URL"}
                  rows={5}
                  minLength={1}
                  maxLength={600}
                  value={hsText}
                  onChange={(e) => setHsText(e.target.value)}
                />
              </div>
            </>
          )}

          {(hsType === "Image" || hsType === "Video" || hsType === "Audio") && (
            <div className="space-y-sm">
              <input
                className="hidden"
                type="file"
                id="file"
                accept={
                  hsType === "Image"
                    ? "image/png, image/jpeg, image/jpg"
                    : hsType === "Video"
                      ? "video/mp4"
                      : hsType === "Audio"
                        ? "audio/mp3"
                        : ""
                }
                onChange={(e) => setHsFile(e.target.files?.[0])}
              />
              <Button
                primary
                onClick={() => {
                  const fileInput = document.getElementById(
                    "file",
                  ) as HTMLInputElement;
                  fileInput.click();
                }}
              >
                Choose File
              </Button>
              <p className="pt-sm text-stone-500">
                {hsFile ? hsFile.name : "No file chosen"}
              </p>
            </div>
          )}

          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={onCancel}>Cancel</Button>
            <Button primary onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Popup>
  );
}
