import { useState, useCallback, useEffect, useRef } from "react";
import ManageCard from "../../components/managecard";
import { ManagedContentProps } from "../../components/managecard";
import { Api } from "../../services/Api";
import { useSanctum } from "react-sanctum";
import { useLocation } from "react-router-dom";
import { forEach } from "lodash";

export default function Library() {
  const { authenticated } = useSanctum();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(0);
  const [content, setContent] = useState<ManagedContentProps[]>([]);
  const sentinelRef = useRef(null);
  const location = useLocation();

  const refreshContent = useCallback(
    (clear = true) => {
      setLoading(true);
      let newContent = content;
      let current_page = page;
      current_page = clear ? 1 : page + 1;
      newContent = clear ? [] : newContent;
      setPage(current_page);
      const params = new URLSearchParams({
        page: current_page.toString(),
      });
      Api(`/content?${params.toString()}`)
        .then((data) => {
          forEach(data.data, (item) => {
            newContent.push({
              content_id: item.id,
              is_private: item.is_private,
              image: item.thumbnails[0] || "/images/placeholder.png",
              title: item.description.title,
              status: item.status,
              created_at: new Date(item.created_at),
              price_inc_tax_as_currency: item.price_inc_tax_as_currency,
              likes: item.like_count || 0,
              disabled: false,
              disabled_reason: "",
              owners: item.owner_count,
              editOnClick: () => {},
            });
          });
          if (data.data !== undefined) setContent(newContent);
          if (data.last_page) setMaxPages(data.last_page);
          if (data.current_page) setPage(data.current_page);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [content, page],
  );

  useEffect(() => {
    if (!authenticated) return;
    refreshContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && page > 0 && page < maxPages && !loading) {
        if (!location.pathname.startsWith("/view")) {
          refreshContent(false);
        }
      }
    }, options);

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [refreshContent, page, maxPages, loading, location]);

  return (
    <div className="flex-1 space-y-md p-md">
      <div className="grid gap-md md:grid-cols-2 lg:grid-cols-1">
        {content.map((content, index) => (
          <ManageCard
            key={index}
            content_id={content.content_id}
            is_private={content.is_private}
            image={content.image}
            title={content.title}
            created_at={content.created_at}
            price_inc_tax_as_currency={content.price_inc_tax_as_currency}
            likes={content.likes}
            status={content.status}
            owners={content.owners}
            editOnClick={() => {}}
            enableHotspots={true}
          />
        ))}
      </div>
      <div
        ref={sentinelRef}
        style={{ height: "10px" }}
        className="bg-transparent mb-lg mt-lg flex items-center justify-center font-semibold text-slate-100"
      >
        {page === maxPages ? "You've reached the end!" : null}
      </div>
    </div>
  );
}
