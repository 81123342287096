import Button from "./button";
import Checkbox from "./checkbox";
import Chip from "./chip";
import * as changeCase from "change-case";
import LikeIcon from "../icons/like";
import { Link } from "react-router-dom";

export interface ManagedContentProps {
  content_id: number;
  is_private: Boolean;
  image: string;
  title: string;
  created_at: Date;
  price_inc_tax_as_currency: string;
  likes: number;
  status: "draft" | "pending" | "approved" | "denied" | "archived";
  selected_content?: number[];
  setSelectedContent?: (content: number[]) => void;
  disabled?: boolean;
  disabled_reason?: string;
  selected?: boolean;
  owners: number;
  enableHotspots?: boolean;
  editOnClick: () => void;
  publicOnClick?: () => void;
}
export default function ManageCard({
  content_id,
  is_private,
  image,
  title,
  created_at,
  price_inc_tax_as_currency,
  likes,
  status,
  selected_content,
  setSelectedContent,
  selected,
  disabled,
  disabled_reason,
  editOnClick,
  publicOnClick,
  enableHotspots = false,
}: ManagedContentProps) {
  const chipColour = () => {
    switch (status) {
      case "draft":
      case "pending":
      case "archived":
        return "primary";
      case "approved":
        return "success";
      case "denied":
        return "danger";
    }
  };

  return (
    <article className="flex min-w-0 flex-col gap-md rounded-md bg-slate-400 p-md lg:flex-row-reverse">
      <div className="flex items-center justify-between gap-md">
        <Chip text={changeCase.capitalCase(status)} colour={chipColour()} />
        {setSelectedContent && selected_content && (
          <div className="flex items-center gap-md">
            <Button onClick={editOnClick} size="sm">
              Edit
            </Button>
            {is_private === true && (
              <Button onClick={publicOnClick} size="sm">
                Make Public
              </Button>
            )}
            <Checkbox
              selected={selected ? selected : false}
              disabled={disabled}
              disabled_reason={disabled_reason}
              id={content_id.toString()}
              onChange={(isSelected: boolean) => {
                if (setSelectedContent && selected_content) {
                  if (isSelected) {
                    setSelectedContent([...selected_content, content_id]);
                  } else {
                    setSelectedContent(
                      selected_content.filter((id) => id !== content_id),
                    );
                  }
                }
              }}
            />
          </div>
        )}
        {enableHotspots && (
          <Link to={`/library/hotspots/${content_id}`}>
            <Button onClick={() => {}} size="sm">
              Hotspots
            </Button>
          </Link>
        )}
      </div>

      <div className="flex min-w-0 flex-1 flex-col gap-md lg:flex-row">
        <img
          src={image}
          alt={title}
          width={96}
          height={64}
          className="hidden rounded-md lg:block"
        />

        <img
          src={image}
          alt={title}
          width={960}
          height={640}
          className="rounded-md lg:hidden"
        />

        <div className="min-w-0 space-y-sm">
          <div className="flex items-center gap-sm">
            {is_private === true && <Chip text="Private" colour="primary" />}
            <h2 className="truncate">{title}</h2>
          </div>

          <div className="flex gap-md text-slate-100">
            <span>{created_at.toLocaleDateString()}</span>
            <span>{price_inc_tax_as_currency}</span>
            <div className="flex gap-sm">
              <LikeIcon />
              <span>{likes}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
