import { renderRoutes } from "./generate-route";
import GenericLayout from "./layout/generic";
import AccountLayout from "./layout/account";
import AuthLayout from "./layout/auth";
import BrowseLayout from "./layout/browse";
import Account from "./pages/account/account";
import Categories from "./pages/account/categories";
import Tags from "./pages/account/tags";
import Content from "./pages/account/content";
import Dashboard from "./pages/account/dashboard";
import Library from "./pages/account/library";
import Success from "./pages/account/success";
import Users from "./pages/account/users";
import Login from "./pages/auth/login";
import Forgot from "./pages/auth/forgot";
import Reset from "./pages/auth/reset";
import Register from "./pages/auth/register";
import Browse from "./pages/browse/browse";
import Creator from "./pages/browse/creator";
import Likes from "./pages/account/likes";
import Subscriptions from "./pages/browse/subscriptions";
import View from "./pages/view/view";
import { Link } from "react-router-dom";
import HotspotEditor from "./pages/account/hotspot-editor";

function NotFound() {
  return (
    <div className="space-y-lg">
      <div className="space-y-md">
        <h1 className="text-xl">Page Not Found</h1>
        <p className="text-slate-100">
          The page you are looking for doesn't exist or has been removed.
        </p>
        <p>
          <Link to="/browse" className="text-slate-100 hover:text-slate-200">
            Click here to return to the catalogue
          </Link>
        </p>
      </div>
    </div>
  );
}

const routesDefinition = [
  {
    layout: AuthLayout,
    routes: [
      {
        name: "Login",
        path: "/login",
        component: Login,
      },
      {
        name: "Forgot",
        path: "/forgot",
        component: Forgot,
      },
      {
        name: "Reset",
        path: "/reset",
        component: Reset,
      },
      {
        name: "Register",
        path: "/register",
        component: Register,
      },
    ],
  },
  {
    layout: BrowseLayout,
    routes: [
      {
        name: "Browse",
        path: "/browse",
        component: Browse,
      },
      {
        name: "Latest",
        path: "/latest",
        component: Browse,
      },
      {
        name: "Popular",
        path: "/popular",
        component: Browse,
      },
      {
        name: "Subscriptions",
        path: "/subscriptions",
        component: Subscriptions,
      },
      {
        name: "Creator",
        path: "/creator/:slug",
        component: Creator,
      },
      {
        name: "View",
        path: "/view/:id",
        component: View,
      },
    ],
  },
  {
    layout: AccountLayout,
    routes: [
      {
        name: "Success",
        path: "/success",
        component: Success,
      },
      {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        user_types: ["admins"],
      },
      {
        name: "Content",
        path: "/content",
        component: Content,
      },
      {
        name: "Hotspots",
        path: "/library/hotspots/:id",
        component: HotspotEditor,
      },
      {
        name: "Account",
        path: "/account",
        component: Account,
      },
      {
        name: "Categories",
        path: "/categories",
        component: Categories,
        user_types: ["admins"],
      },
      {
        name: "Tags",
        path: "/tags",
        component: Tags,
        user_types: ["admins"],
      },
      {
        name: "Library",
        path: "/library",
        component: Library,
        user_types: ["users"],
      },
      {
        name: "My Likes",
        path: "/likes",
        component: Likes,
        user_types: ["users", "creators", "admins"],
      },
      {
        name: "Users",
        path: "/users",
        component: Users,
        user_types: ["admins"],
      },
    ],
  },
  {
    layout: GenericLayout,
    routes: [
      {
        name: "Not Found",
        path: "*",
        component: NotFound,
      },
    ],
  },
];

export const Routes = await renderRoutes(routesDefinition);
